import React from "react"
import SiteLayout from "../components/layouts/SiteLayout"
import SEO from "../components/SEO"
import Centered from "../components/layouts/ViewportCentered"
import Container from "../components/layouts/container/Container"

const Completed = () => (
  <SiteLayout>
    <SEO title="Thank you"/>
    <Container>
      <Centered>
        <h1>Thank you</h1>
      </Centered>
    </Container>
  </SiteLayout>
)

export default Completed
